import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fdfe5a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-card-result-1-root" }
const _hoisted_2 = ["data-label"]
const _hoisted_3 = { class: "card-3" }
const _hoisted_4 = { class: "card-2" }
const _hoisted_5 = { class: "card-1" }
const _hoisted_6 = ["data-label"]
const _hoisted_7 = { class: "card-1" }
const _hoisted_8 = { class: "card-2" }
const _hoisted_9 = { class: "card-3" }
const _hoisted_10 = ["data-color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.gameType !== -1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["container", { 'is-reverse': _ctx.gameType === 2 }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["blue-group", { flashing: _ctx.winner === 3 }])
          }, [
            _createElementVNode("div", {
              class: "cards",
              "data-label": _ctx.bluePointAndlabel
            }, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.blueThirdCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.blueThirdCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.blueSecondCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.blueSecondCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.blueFirstCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.blueFirstCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["red-group", { flashing: _ctx.winner === 1 }])
          }, [
            _createElementVNode("div", {
              class: "cards",
              "data-label": _ctx.redPointAndlabel
            }, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.redFirstCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.redFirstCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.redSecondCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.redSecondCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.redThirdCard !== '')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["poker", _ctx.redThirdCard])
                    }, null, 2))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_6)
          ], 2),
          _createElementVNode("div", {
            class: "result",
            "data-color": _ctx.winner
          }, _toDisplayString(_ctx.resultLabel), 9, _hoisted_10)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}