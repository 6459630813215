
import { useBaccaratAndDragontigerBetBox } from '@/composables/useBaccaratAndDragontigerBetBox'
import { EventBuss } from '@/types/global'
import {
    ChipItem,
    Emitter,
    PropType,
    StyleValue,
    TableData,
    computed,
    defineComponent,
    inject,
    onBeforeUnmount,
    onMounted
} from 'vue'

export default defineComponent({
    props: {
        betType: {
            type: Number as PropType<number>,
            required: true
        },
        rect: {
            type: Object as PropType<{
                offsetTop: number
                offsetLeft: number
                w: number
                h: number
            }>,
            required: true
        },
        chipsAttr: {
            type: Object as PropType<{
                width: number
                height: number
                offset: number
            }>,
            default: () => ({ width: 20, height: 12, offset: 5 }),
            required: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            required: false
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        tempBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        desk: {
            type: Object as PropType<TableData>,
            required: false
        },
        display: {
            type: String as PropType<'pc' | 'mobile'>,
            required: false,
            default: () => 'pc'
        },
        betAmountOffset: {
            type: Number as PropType<number>,
            required: false,
            default: () => 0
        }
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const {
            chipsUI,
            chipWrapStyle,
            chipStyle,
            handleOnPlaceChip,
            resetBetUI,
            removeTempUIBet,
            applyTempUIBet
        } = useBaccaratAndDragontigerBetBox(
            props.betType,
            props.rect,
            props.chipsAttr,
            props.display
        )

        onMounted(() => {
            emitter.on(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        const amount = computed(() => {
            const confirmedBet = props.confirmedBet ?? 0
            const tempBet = props.tempBet ?? 0
            return confirmedBet + tempBet
        })

        const betAmountStyle = computed((): StyleValue => {
            if (props.betAmountOffset === 0) return {}

            const suffix = props.display === 'mobile' ? '--mp' : '--pc'
            return {
                left: `var(${suffix}${props.betAmountOffset})`,
                right: 'auto',
                margin: '0'
            }
        })

        return {
            amount,
            chipWrapStyle,
            chipsUI,
            betAmountStyle,
            applyTempUIBet,
            handleOnPlaceChip,
            removeTempUIBet,
            resetBetUI,
            chipStyle
        }
    }
})
