
import { useBaccaratAndDragontigerBetgroup } from '@/composables/useBaccaratAndDragontigerBetgroup'
import {
    ChipItem,
    PropType,
    TableData,
    computed,
    defineComponent,
    ref,
    watch
} from 'vue'
import {
    PcPlayerPairSVG,
    PcTieSVG,
    PcBankerPairSVG,
    PcPlayerSVG,
    PcBankerSVG
} from '@/components/svg/pc-bet-group-classic'
import BetBox from '@/components/bet/pc-classic-bet-box-v1.vue'

export default defineComponent({
    emits: ['on-update'],
    components: {
        'pc-svg-player-pair': PcPlayerPairSVG,
        'pc-svg-tie': PcTieSVG,
        'pc-svg-banker-pair': PcBankerPairSVG,
        'pc-svg-player': PcPlayerSVG,
        'pc-svg-banker': PcBankerSVG,
        'bet-box': BetBox
    },
    props: {
        mData: {
            type: Object as PropType<TableData>,
            require: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        },
        isMemberCommission: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => true
        },
        win: {
            type: Array as PropType<number[]>,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const {
            pBet,
            bBet,
            ppBet,
            bpBet,
            tBet,
            drBet,
            tgBet,
            endGame,
            bankerBox,
            playerBox,
            bankerPBox,
            tieBox,
            playerPBox,
            tempPBet,
            tempPpBet,
            tempTBet,
            tempBpBet,
            tempBBet,
            memberId,
            placeAbet,
            resetTempBet,
            resetBetUI,
            confirmTempBet
        } = useBaccaratAndDragontigerBetgroup(emit, true)

        const gameType = computed(() => {
            let result = ''
            if (props.mData) result = props.mData.gameTypeNo
            else result = ''

            return result
        })

        watch(
            () => props.mData,
            (val, old) => {
                if (val && val.expand.betFormList) {
                    let _pBet = 0
                    let _bBet = 0
                    let _ppBet = 0
                    let _bpBet = 0
                    let _tBet = 0
                    let _drBet = 0
                    let _tgBet = 0

                    const { betFormList } = val.expand
                    betFormList.forEach((i) => {
                        if (i.memberId === memberId.value) {
                            const { playName, amount } = i
                            if (playName === '庄') {
                                _bBet += amount
                            } else if (playName === '庄对') {
                                _bpBet += amount
                            } else if (playName === '闲') {
                                _pBet += amount
                            } else if (playName === '闲对') {
                                _ppBet += amount
                            } else if (playName === '和') {
                                _tBet += amount
                            } else if (playName === '龙') {
                                _drBet += amount
                            } else if (playName === '虎') {
                                _tgBet += amount
                            }
                        }
                    })
                    pBet.value = _pBet
                    bBet.value = _bBet
                    ppBet.value = _ppBet
                    bpBet.value = _bpBet
                    tBet.value = _tBet
                    drBet.value = _drBet
                    tgBet.value = _tgBet
                }
            },
            { immediate: true, deep: true }
        )

        return {
            bBet,
            pBet,
            bpBet,
            tBet,
            ppBet,
            tempPBet,
            tempPpBet,
            tempTBet,
            tempBpBet,
            tempBBet,
            playerPBox,
            tieBox,
            bankerPBox,
            playerBox,
            bankerBox,
            gameType,
            placeAbet,
            resetTempBet,
            resetBetUI,
            confirmTempBet,
            endGame
        }
    }
})
