import { ChipItem, Emitter, TableData, computed, inject, ref, watch } from 'vue'
import { isClicked } from '@/utils'
import { BetType, EventBuss } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { useStore } from '@/store'
import { AppMutation } from '@/store/types'
import { saveBet } from '@/api'
import { GAMETYPE } from '@/components/roadmap/datamodel/types'

export function useBaccaratAndDragontigerBetgroup(
    emit: (event: 'on-update', ...args: any[]) => void,
    playSoundEffect: boolean
) {
    const emitter = inject('emitter') as Emitter
    const store = useStore()

    const pBet = ref<number>(0)
    const bBet = ref<number>(0)
    const ppBet = ref<number>(0)
    const bpBet = ref<number>(0)
    const tBet = ref<number>(0)
    const drBet = ref<number>(0)
    const tgBet = ref<number>(0)

    const tempPBet = ref<number>(0)
    const tempBBet = ref<number>(0)
    const tempPpBet = ref<number>(0)
    const tempBpBet = ref<number>(0)

    const tempTBet = ref<number>(0)
    const tempDrBet = ref<number>(0)
    const tempTgBet = ref<number>(0)

    // #region betbox ref
    // classic baccarat
    const bankerBox = ref()
    const playerBox = ref()
    const tieBox = ref()
    const bankerPBox = ref()
    const playerPBox = ref()
    // classic dragon tiger
    const dragonBox = ref()
    const tigerBox = ref()
    const tieTGBox = ref()
    // endregion

    const placeAbet = (
        e: MouseEvent,
        _betType: number,
        mData?: TableData,
        chip?: ChipItem
    ) => {
        const boxIsClicked = isClicked(e, 0.3)

        if (boxIsClicked) {
            if (!mData) return

            if (mData.status !== 1) {
                // countdown timer is done, no betting allowed
                emitter.emit(EventBuss.SMALL_TOAST, {
                    msg: '当前不能下注',
                    id: mData?.id || 0
                })
                return
            }

            const _amount = chip?.value || 0

            if (_amount > 0) {
                // check total temp bet is sufficient
                if (!isBetIsSufficient(_amount)) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: ' 余额不足，投注失败！',
                        id: mData?.id || 0
                    })

                    return
                }

                switch (_betType) {
                    case BetType.BANKER:
                        tempBBet.value += _amount
                        break
                    case BetType.DRAGON:
                        tempDrBet.value += _amount
                        break
                    case BetType.PLAYER:
                        tempPBet.value += _amount
                        break
                    case BetType.TIGER:
                        tempTgBet.value += _amount
                        break
                    case BetType.BC_TIE:
                    case BetType.DT_TIE:
                        tempTBet.value += _amount
                        break
                    case BetType.PLAYER_PAIR:
                        tempPpBet.value += _amount
                        break
                    case BetType.BANKER_PAIR:
                        tempBpBet.value += _amount
                        break
                }

                emitter.emit(EventBuss.PLACE_CHIP, {
                    t: _betType,
                    amt: _amount,
                    type: 'temp',
                    deskId: mData.id,
                    chip: chip,
                    desk: mData
                })

                if (playSoundEffect) {
                    audioPlayer.Play(SOUNDKEY.BET_PLACE)
                }

                callUpdateCallback()
            }
        }
    }

    const isBetIsSufficient = (amount: number) => {
        const total =
            tempPBet.value +
            tempBBet.value +
            tempPpBet.value +
            tempBpBet.value +
            tempTBet.value +
            tempDrBet.value +
            tempTgBet.value +
            amount

        return coin.value >= total
    }

    const callUpdateCallback = () => {
        const hasTempBet =
            tempPBet.value +
                tempBBet.value +
                tempPpBet.value +
                tempBpBet.value +
                tempTBet.value +
                tempDrBet.value +
                tempTgBet.value >
            0

        emit('on-update', hasTempBet)
    }

    const resetTempBet = (gameType: string) => {
        tempPBet.value = 0
        tempBBet.value = 0
        tempPpBet.value = 0
        tempBpBet.value = 0
        tempTBet.value = 0
        tempDrBet.value = 0
        tempTgBet.value = 0

        const gType = gameType === 'G002' ? 1 : 2

        // classic

        if (gType === GAMETYPE.baccarat) {
            // bac
            if (bankerBox.value) bankerBox.value.removeTempUIBet()
            if (playerBox.value) playerBox.value.removeTempUIBet()
            if (tieBox.value) tieBox.value.removeTempUIBet()
            if (bankerPBox.value) bankerPBox.value.removeTempUIBet()
            if (playerPBox.value) playerPBox.value.removeTempUIBet()
        } else if (gType === GAMETYPE.dragonTiger) {
            // dragon tiger
            if (dragonBox.value) dragonBox.value.removeTempUIBet()
            if (tigerBox.value) tigerBox.value.removeTempUIBet()
            if (tieTGBox.value) tieTGBox.value.removeTempUIBet()
        }

        callUpdateCallback()
    }

    const resetBetUI = (gameType: string, mData?: TableData) => {
        const gType = gameType === 'G002' ? 1 : 2

        if (mData) {
            const { id } = mData

            let chipId = -1
            if (userChip.value > 0) chipId = userChip.value
            else chipId = chipDefault.value[0].id

            const chipValue = chipDefault.value.find((i) => i.id == chipId)

            if (gType === GAMETYPE.baccarat) {
                // bac
                if (bankerBox.value && bBet.value > 0)
                    bankerBox.value.handleOnPlaceChip({
                        t: BetType.BANKER,
                        amt: bBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                if (playerBox.value && pBet.value > 0) {
                    playerBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER,
                        amt: pBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }

                if (tieBox.value && tBet.value > 0) {
                    tieBox.value.handleOnPlaceChip({
                        t: BetType.BC_TIE,
                        amt: tBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }

                if (bankerPBox.value && bpBet.value > 0) {
                    bankerPBox.value.handleOnPlaceChip({
                        t: BetType.BANKER_PAIR,
                        amt: bpBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }

                if (playerPBox.value && ppBet.value > 0) {
                    playerPBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER_PAIR,
                        amt: ppBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }
            } else {
                if (tigerBox.value && tgBet.value > 0) {
                    tigerBox.value.handleOnPlaceChip({
                        t: BetType.TIGER,
                        amt: tgBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }

                if (dragonBox.value && drBet.value > 0) {
                    dragonBox.value.handleOnPlaceChip({
                        t: BetType.DRAGON,
                        amt: drBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }

                if (tieTGBox.value && tBet.value > 0) {
                    tieTGBox.value.handleOnPlaceChip({
                        t: BetType.DT_TIE,
                        amt: tBet.value,
                        type: 'confirmed',
                        deskId: id,
                        chip: chipValue,
                        desk: mData
                    })
                }
            }
        }
    }

    const confirmTempBet = (gameType: string, mData?: TableData) => {
        if (!mData) return
        let _params: any = {}
        const { expand, id } = mData

        const gType = gameType === 'G002' ? 1 : 2
        if (gType === 1) {
            _params = {
                memberId: memberId.value,
                tableId: id,
                shoeNo: expand.shoeNo,
                juNo: expand.juNo,
                gameNo: expand.gameNo,
                zhuang: tempBBet.value,
                he: tempTBet.value,
                xian: tempPBet.value,
                zhuangDui: tempBpBet.value,
                xianDui: tempPpBet.value,
                token: token.value
            }
        } else if (gType === 2) {
            _params = {
                memberId: memberId.value,
                tableId: id,
                shoeNo: expand.shoeNo,
                juNo: expand.juNo,
                gameNo: expand.gameNo,
                zhuang: tempDrBet.value,
                he: tempTBet.value,
                xian: tempTgBet.value,
                token: token.value
            }
        }

        store.commit(AppMutation.SHOW_LOADING)

        saveBet(_params)
            .then((response) => {
                store.commit(AppMutation.HIDE_LOADING)
                if (response.data.status === 100) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: response.data.description,
                        id: mData?.id || 0
                    })

                    resetTempBet(gameType)
                } else if (response.data.status === 200) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '下注成功',
                        id: mData?.id || 0
                    })
                    audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                    applyTempBet()
                }
            })
            .catch((e) => {
                store.commit(AppMutation.HIDE_LOADING)
                resetTempBet(gameType)
                emitter.emit(EventBuss.SMALL_TOAST, {
                    msg: '当前网络不佳，请重新尝试',
                    id: mData?.id || 0
                })
            })
    }

    const applyTempBet = () => {
        tempPBet.value = 0
        tempBBet.value = 0
        tempPpBet.value = 0
        tempBpBet.value = 0
        tempTBet.value = 0
        tempDrBet.value = 0
        tempTgBet.value = 0

        if (bankerBox.value) bankerBox.value.applyTempUIBet()
        if (playerBox.value) playerBox.value.applyTempUIBet()
        if (tieBox.value) tieBox.value.applyTempUIBet()
        if (bankerPBox.value) bankerPBox.value.applyTempUIBet()
        if (playerPBox.value) playerPBox.value.applyTempUIBet()

        // dragon tiger
        if (dragonBox.value) dragonBox.value.applyTempUIBet()
        if (tigerBox.value) tigerBox.value.applyTempUIBet()
        if (tieTGBox.value) tieTGBox.value.applyTempUIBet()

        callUpdateCallback()
    }
    const endGame = (gameType: string) => {
        // bac
        if (bankerBox.value) bankerBox.value.resetBetUI()
        if (playerBox.value) playerBox.value.resetBetUI()
        if (tieBox.value) tieBox.value.resetBetUI()
        if (bankerPBox.value) bankerPBox.value.resetBetUI()
        if (playerPBox.value) playerPBox.value.resetBetUI()

        // dragon tiger
        if (dragonBox.value) dragonBox.value.resetBetUI()
        if (tigerBox.value) tigerBox.value.resetBetUI()
        if (tieTGBox.value) tieTGBox.value.resetBetUI()

        resetTempBet(gameType)
    }

    const coin = computed(() => store.getters['coin'])
    const memberId = computed(() => store.getters['memberid'])
    const token = computed(() => store.getters['token'])
    const userChip = computed(() => store.getters['currentChip'])
    const chipDefault = computed((): ChipItem[] => store.getters['chipDefault'])

    return {
        // confirmed bet
        pBet,
        bBet,
        ppBet,
        bpBet,
        tBet,
        drBet,
        tgBet,
        // temp Baccarat
        tempPBet,
        tempBBet,
        tempPpBet,
        tempBpBet,
        // temp Dragon tiger
        tempTgBet,
        tempDrBet,
        tempTBet,

        // classic baccarat
        bankerBox,
        playerBox,
        tieBox,
        bankerPBox,
        playerPBox,
        // classic dragon tiger
        dragonBox,
        tigerBox,
        tieTGBox,
        memberId,
        placeAbet,
        endGame,
        applyTempBet,
        confirmTempBet,
        resetBetUI,
        resetTempBet
    }
}
