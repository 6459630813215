import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0459aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-classic-bet-group-v1-root" }
const _hoisted_2 = {
  key: 0,
  class: "g002"
}
const _hoisted_3 = {
  key: 1,
  class: "g003"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pc_svg_player_pair = _resolveComponent("pc-svg-player-pair")!
  const _component_bet_box = _resolveComponent("bet-box")!
  const _component_pc_svg_tie = _resolveComponent("pc-svg-tie")!
  const _component_pc_svg_banker_pair = _resolveComponent("pc-svg-banker-pair")!
  const _component_pc_svg_player = _resolveComponent("pc-svg-player")!
  const _component_pc_svg_banker = _resolveComponent("pc-svg-banker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.gameType === 'G002')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "player-pair-box",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.placeAbet($event, 6, _ctx.mData, _ctx.chip)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flash", { flashing: _ctx.win.indexOf(6) !== -1 }])
            }, null, 2),
            _createVNode(_component_pc_svg_player_pair),
            _createVNode(_component_bet_box, {
              ref: "playerPBox",
              rect: { offsetLeft: 5, offsetTop: 4, w: 129, h: 67 },
              "bet-type": 6,
              desk: _ctx.mData,
              chip: _ctx.chip,
              "temp-bet": _ctx.tempPpBet,
              "confirmed-bet": _ctx.ppBet,
              "chips-attr": { width: 30, height: 30, offset: 5 },
              display: 'mobile'
            }, null, 8, ["desk", "chip", "temp-bet", "confirmed-bet"])
          ]),
          _createElementVNode("div", {
            class: "tie-box",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.placeAbet($event, 2, _ctx.mData, _ctx.chip)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flash", { flashing: _ctx.win.indexOf(2) !== -1 }])
            }, null, 2),
            _createVNode(_component_pc_svg_tie),
            _createVNode(_component_bet_box, {
              ref: "tieBox",
              rect: { offsetLeft: 5, offsetTop: 4, w: 90, h: 67 },
              "bet-type": 2,
              desk: _ctx.mData,
              chip: _ctx.chip,
              "temp-bet": _ctx.tempTBet,
              "confirmed-bet": _ctx.tBet,
              "chips-attr": { width: 30, height: 30, offset: 5 },
              display: 'mobile'
            }, null, 8, ["desk", "chip", "temp-bet", "confirmed-bet"])
          ]),
          _createElementVNode("div", {
            class: "banker-pair-box",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.placeAbet($event, 7, _ctx.mData, _ctx.chip)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flash", { flashing: _ctx.win.indexOf(7) !== -1 }])
            }, null, 2),
            _createVNode(_component_pc_svg_banker_pair),
            _createVNode(_component_bet_box, {
              ref: "bankerPBox",
              rect: { offsetLeft: 5, offsetTop: 4, w: 126, h: 67 },
              "bet-type": 7,
              desk: _ctx.mData,
              chip: _ctx.chip,
              "temp-bet": _ctx.tempBpBet,
              "confirmed-bet": _ctx.bpBet,
              "chips-attr": { width: 30, height: 30, offset: 5 },
              display: 'mobile'
            }, null, 8, ["desk", "chip", "temp-bet", "confirmed-bet"])
          ]),
          _createElementVNode("div", {
            class: "player-box",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.placeAbet($event, 1, _ctx.mData, _ctx.chip)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flash", { flashing: _ctx.win.indexOf(1) !== -1 }])
            }, null, 2),
            _createVNode(_component_pc_svg_player),
            _createVNode(_component_bet_box, {
              ref: "playerBox",
              rect: { offsetLeft: 5, offsetTop: 4, w: 178, h: 75 },
              "bet-type": 1,
              desk: _ctx.mData,
              chip: _ctx.chip,
              "temp-bet": _ctx.tempPBet,
              "confirmed-bet": _ctx.pBet,
              "chips-attr": { width: 30, height: 30, offset: 5 },
              display: 'mobile'
            }, null, 8, ["desk", "chip", "temp-bet", "confirmed-bet"])
          ]),
          _createElementVNode("div", {
            class: "banker-box",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.placeAbet($event, 0, _ctx.mData, _ctx.chip)))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flash", { flashing: _ctx.win.indexOf(0) !== -1 }])
            }, null, 2),
            _createVNode(_component_pc_svg_banker),
            _createVNode(_component_bet_box, {
              ref: "bankerBox",
              rect: { offsetLeft: 5, offsetTop: 4, w: 176, h: 75 },
              "bet-type": 0,
              desk: _ctx.mData,
              chip: _ctx.chip,
              "temp-bet": _ctx.tempBBet,
              "confirmed-bet": _ctx.bBet,
              "chips-attr": { width: 30, height: 30, offset: 5 },
              display: 'mobile'
            }, null, 8, ["desk", "chip", "temp-bet", "confirmed-bet"])
          ])
        ]))
      : (_ctx.gameType === 'G003')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
  ]))
}